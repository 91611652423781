import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import firebase from 'firebase/compat/app';
import { Router } from '@angular/router';
import { User } from '../models/user/User';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _loggedIn = false;
  private _userId = null;

  constructor(private auth: AngularFireAuth) {}

  recoverPassword(email: string): Observable<void> {
    return new Observable<void>((observer) => {
      this.auth
        .sendPasswordResetEmail(email)
        .then(() => {
          observer.next();
          observer.complete();
        })
        .catch((error) => {
          observer.error(error);
          observer.complete();
        });
    });
  }

  login(email: string, password: string): Observable<User> {
    return new Observable<User>((observer) => {
      this.auth
        .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
        .then((user) => {
          this.auth
            .signInWithEmailAndPassword(email, password)
            .then((firebaseUser: firebase.auth.UserCredential) => {
              observer.next({ email, id: firebaseUser.user.uid });
              observer.complete();
            })
            .catch((error) => {
              observer.error(error);
              observer.complete();
            });
        });
    });
  }

  isLoggedIn() {
    return this._loggedIn;
  }

  getUserId() {
    return this._userId;
  }

  logout() {
    console.log('logout');
    this._userId = null;
    this._loggedIn = false;
    this.auth.signOut();
  }
}
