import { Component, OnInit, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { IonicModule } from '@ionic/angular';
import { NgIf, AsyncPipe } from '@angular/common';
import { AppState } from 'store/AppState';
import { loadingFeature } from 'store/loading/loading.state';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
  standalone: true,
  imports: [NgIf, IonicModule, AsyncPipe],
})
export class LoadingComponent {
  store = inject(Store<AppState>);
  loadingShow$ = this.store.select(loadingFeature.selectShow);
}
