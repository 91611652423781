import { createFeature, createReducer, on } from '@ngrx/store';
import { LoginState } from './LoginState';
import {
  login,
  loginFail,
  loginSuccess,
  logout,
  recoverPassword,
  recoverPasswordFail,
  recoverPasswordSuccess,
} from './login.actions';
import { AppInitialState } from '../AppInitialState';

export const initialState: LoginState = AppInitialState.login;

export const reducer = createReducer(
  initialState,
  on(recoverPassword, (currentState) => {
    return {
      ...currentState,
      error: null,
      isRecoveringPassword: true,
      isRecoveredPassword: false,
    };
  }),
  on(recoverPasswordSuccess, (currentState) => {
    return {
      ...currentState,
      error: null,
      isRecoveringPassword: false,
      isRecoveredPassword: true,
    };
  }),
  on(recoverPasswordFail, (currentState, action) => {
    return {
      ...currentState,
      error: action.error,
      isRecoveringPassword: false,
      isRecoveredPassword: false,
    };
  }),
  on(login, (currentState) => {
    return {
      ...currentState,
      error: null,
      isLoggingIn: true,
      isLoggedIn: false,
    };
  }),
  on(loginSuccess, (currentState, action) => {
    return {
      ...currentState,
      error: null,
      isLoggingIn: false,
      isLoggedIn: true,
      user: action.user,
    };
  }),
  on(loginFail, (currentState, action) => {
    return {
      ...currentState,
      error: action.error,
      isLoggingIn: false,
      isLoggedIn: false,
    };
  }),
  on(logout, () => {
    return { ...initialState, user: null };
  })
);

export const loginFeature = createFeature({
  name: 'loginFeatureKey',
  reducer,
});
