import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  login,
  loginFail,
  loginSuccess,
  logout,
  logoutSuccess,
  recoverPassword,
  recoverPasswordFail,
  recoverPasswordSuccess,
} from './login.actions';
import { catchError, finalize, map, of, switchMap, tap } from 'rxjs';
import { AuthService } from 'app/services/auth.service';
import { NavController } from '@ionic/angular';

@Injectable()
export default class LoginEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private navController: NavController
  ) {}

  recoverPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(recoverPassword),
      switchMap((payload: { email: string }) =>
        this.authService.recoverPassword(payload.email).pipe(
          map(() => recoverPasswordSuccess()),
          catchError((error) => of(recoverPasswordFail({ error })))
        )
      )
    )
  );

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(login),
      switchMap((payload: { email: string; password: string }) =>
        this.authService.login(payload.email, payload.password).pipe(
          map((user) => loginSuccess({ user })),
          catchError((error) => of(loginFail({ error })))
        )
      )
    )
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(logout),
      map(() => {
        this.authService.logout();
        this.navController.navigateBack(['/']);
        return logoutSuccess();
      })
    )
  );
}
