import { createFeature, createReducer, on } from '@ngrx/store';
import { LoadingState } from './LoadingState';
import { show, hide } from './loading.actions';

const initialState: LoadingState = {
  show: false,
};

const reducer = createReducer(
  initialState,
  on(show, () => {
    return { show: true };
  }),
  on(hide, () => {
    return { show: false };
  })
);

export const loadingFeature = createFeature({
  name: 'loadingFeatureKey',
  reducer,
});
