// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  firebaseConfig: {
    apiKey: "AIzaSyCychbq3p2CtKdv1-Q5ABJB7gA_r_En_x0",
    authDomain: "grade-anp.firebaseapp.com",
    projectId: "grade-anp",
    storageBucket: "grade-anp.appspot.com",
    messagingSenderId: "761229602912",
    appId: "1:761229602912:web:67aa3a36a10111fb965305",
  },
  baseURL: "https://grade.simulex.com.br/api",
  production: false,
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
