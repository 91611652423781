import { Routes } from "@angular/router"

export const routes: Routes = [
  {
    path: "",
    redirectTo: "login",
    pathMatch: "full",
  },
  {
    path: "login",
    loadComponent: () => import("./pages/login/login.page").then((m) => m.LoginPage),
  },
  {
    path: "eventos",
    loadComponent: () => import("./pages/eventos/eventos.page").then((m) => m.EventosPage),
  },
  {
    path: "eventos/:gradeId",
    loadComponent: () => import("./pages/evento-detail/evento-detail.page").then((m) => m.EventoDetailPage),
  },
  {
    path: "eventos/:gradeId/cursos/:cursoId",
    loadComponent: () => import("./pages/curso-detail/curso-detail.page").then((m) => m.CursoDetailPage),
  },
  {
    path: "eventos/:gradeId/cursos/:cursoId/disciplinas/:disciplinaId",
    loadComponent: () => import("./pages/disciplina-detail/disciplina-detail.page").then((m) => m.DisciplinaDetailPage),
  },
  {
    path: "eventos/:gradeId/cursos/:cursoId/disciplinas/:disciplinaId/assuntos/:assuntoId",
    loadComponent: () => import("./pages/assunto-detail/assunto-detail.page").then((m) => m.AssuntoDetailPage),
  },
  {
    path: "eventos/:gradeId/professores/:professorId",
    loadComponent: () => import("./pages/professor-detail/professor-detail.page").then((m) => m.ProfessorDetailPage),
  },
  {
    path: "eventos/:gradeId/cursos/:cursoId/turmas/:turmaId",
    loadComponent: () => import("./pages/turma-detail/turma-detail.page").then((m) => m.TurmaDetailPage),
  },
  {
    path: "eventos/:gradeId/locais/:localId",
    loadComponent: () => import("./pages/local-detail/local-detail.page").then((m) => m.LocalDetailPage),
  },
  {
    path: "eventos/:gradeId/localTipos/:localTipoId",
    loadComponent: () => import("./pages/local-tipo-detail/local-tipo-detail.page").then((m) => m.LocalTipoDetailPage),
  },
  {
    path: "eventos/:gradeId/cursos/:cursoId/disciplinas/:disciplinaId/assuntos/:assuntoId/edit",
    loadComponent: () => import("./pages/assunto-edit/assunto-edit.page").then((m) => m.AssuntoEditPage),
  },
  {
    path: "eventos/:gradeId/cursos/:cursoId/disciplinas/:disciplinaId/edit",
    loadComponent: () => import("./pages/disciplina-edit/disciplina-edit.page").then((m) => m.DisciplinaEditPage),
  },
  {
    path: "eventos/:gradeId/cursos/:cursoId/edit",
    loadComponent: () => import("./pages/curso-edit/curso-edit.page").then((m) => m.CursoEditPage),
  },
  {
    path: "eventos/:gradeId/cursos/:cursoId/turmas/:turmaId/edit",
    loadComponent: () => import("./pages/turma-edit/turma-edit.page").then((m) => m.TurmaEditPage),
  },
  {
    path: "eventos/:gradeId/locais/:localId/edit",
    loadComponent: () => import("./pages/local-edit/local-edit.page").then((m) => m.LocalEditPage),
  },
  {
    path: "eventos/:gradeId/localTipos/:localTipoId/edit",
    loadComponent: () => import("./pages/local-tipo-edit/local-tipo-edit.page").then((m) => m.LocalTipoEditPage),
  },
  {
    path: "eventos/:gradeId/professores/:professorId/edit",
    loadComponent: () => import("./pages/professor-edit/professor-edit.page").then((m) => m.ProfessorEditPage),
  },
  {
    path: "eventos/:gradeId/edit",
    loadComponent: () => import("./pages/evento-edit/evento-edit.page").then((m) => m.EventoEditPage),
  },
]
