import { ApplicationConfig, importProvidersFrom, isDevMode } from "@angular/core"
import { RouteReuseStrategy, provideRouter, withComponentInputBinding } from "@angular/router"

import { routes } from "./app.routes"
import { provideState, provideStore } from "@ngrx/store"
import { provideEffects } from "@ngrx/effects"

import * as fromLoading from "store/loading/loading.state"
import * as fromLogin from "store/login/login.state"
import { provideAnimations } from "@angular/platform-browser/animations"
import { provideStoreDevtools } from "@ngrx/store-devtools"
import { provideHttpClient, withInterceptorsFromDi } from "@angular/common/http"
import { AngularFireModule } from "@angular/fire/compat"
import { AngularFireAuthModule } from "@angular/fire/compat/auth"
import { IonicRouteStrategy } from "@ionic/angular"
import { provideIonicAngular } from "@ionic/angular/standalone"
import { provideRouterStore } from "@ngrx/router-store"
import { environment } from "environments/environment"
import LoginEffects from "store/login/login.effects"

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(AngularFireModule.initializeApp(environment.firebaseConfig), AngularFireAuthModule),
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    provideHttpClient(withInterceptorsFromDi()),
    provideIonicAngular({ innerHTMLTemplatesEnabled: true }),
    provideStoreDevtools({ maxAge: 25, logOnly: !isDevMode() }),
    provideRouter(routes, withComponentInputBinding()),
    provideRouterStore(),
    provideStore(),
    provideState(fromLoading.loadingFeature.name, fromLoading.loadingFeature.reducer),
    provideState(fromLogin.loginFeature.name, fromLogin.loginFeature.reducer),
    provideEffects(LoginEffects),
    provideAnimations(),
    provideStoreDevtools({ maxAge: 25, logOnly: !isDevMode() }),
  ],
}
